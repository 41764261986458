.box {
    width: 200px;
    height: 200px;
    background-color: blue;
    display: flex;
    justify-content: center;
    align-items: center;

    .inner-box {
        width: 100px;
        height: 100px;
        background-color: red;
    }
}